<template>
  <div class="container max-w-screen-lg p-4 mx-auto my-6">
    <!-- Tabla -->
    <div class="p-4 w-full border-b flex flex-col gap-y-4">
      <div class="flex gap-x-4 font-bold text-lg">
        <div class="flex-1">Producto</div>
        <div class="flex flex-shrink-0 w-10">Precio</div>
        <div class="flex-shrink-0 w-24 flex justify-end">Cantidad</div>
        <div class="hidden sm:flex flex-shrink-0 w-16 justify-end">Subtotal</div>
      </div>
      <div v-for="product in carrito" :key="product.id" class="flex items-start gap-x-4">
        <!-- Producto -->
        <div class="flex gap-x-2 items-start flex-1 truncate">
          <div class="flex-shrink-0">
            <img :src="product.image" class="w-10 h-10 md:w-20 md:h-20 flex-shrink-0 rounded object-contain"
              alt="Thumbnail" />
          </div>
          <div class="truncate">
            <p class="font-bold text-md lg:text-xl truncate cursor-pointer" :title="product.name"
              @click="goToRepuesto(product.id)">{{ product.nombre }}</p>
            <p class="text-sm truncate">{{ product.producto }}</p>
            <span class="cursor-pointer text-xs text-blue-500 hover:text-blue-400" @click="remove(product.id)">(Eliminar
              producto)</span>
          </div>
        </div>
        <!-- Precio -->
        <div class="flex flex-shrink-0 w-10 justify-end">
          <span class="text-lg font-medium">${{ formatNumber(product.precio) }}</span>
        </div>
        <!-- Cantidad -->
        <div class="flex-shrink-0 w-24 flex flex-col items-end">
          <div class="relative flex flex-row h-8">
            <input-cantidad v-model:value="product.cantidad" :min="1" :max="product.stock" />
          </div>
          <div class="mt-1 text-xs text-gray-400 font-sans">{{ product.stock }} Disponibles</div>
        </div>
        <!-- Total Parcial -->
        <div class="flex-shrink-0 w-16 justify-end hidden sm:flex">
          <span class="text-lg font-medium">${{ formatNumber(getTotalParcial(product.precio, product.cantidad))
          }}</span>
        </div>
      </div>
      <div v-if="carrito.length == 0">No hay productos en el carrito</div>
    </div>

    <!-- Total -->
    <div class="mt-4 w-full flex flex-col sm:flex-row gap-4 sm:gap-0 justify-end">
      <!-- Datos contacto -->
      <div class="flex flex-1" v-if="getFromUser('nombre')">
        <div class="p-2 bg-gray-50 border rounded h-min" style="min-width:15em;">
          <div class="font-bold text-xl">Tus Datos</div>
          <div>{{ getFromUser('nombre') }}</div>
          <div>{{ getFromUser('direccion') }}, {{ getFromUser('cp') }}, {{ getFromUser('ciudad') }}, {{
              getFromUser('provinciaStr')
          }}</div>
          <div>{{ getFromUser('telefono') }}, {{ getFromUser('email') }}</div>
          <div>DNI/CUIT: {{ getFromUser('dni') }}</div>
          <div>{{ getFromUser('facturaStr') }}</div>
          <div class="text-main text-sm cursor-pointer hover:underline" @click="goToDatosCompra">(Modificar datos)</div>
        </div>
      </div>

      <!-- Métodos de pago -->
      <div v-if="carrito.length > 0" class="flex flex-col gap-x-4 gap-y-1 items-start sm:items-end">
        <div class="w-40 flex flex-col justify-start sm:justify-end">
          <div class="flex items-center">
            <div class="font-bold">Subtotal</div>
            <div class="flex flex-1 justify-end font-bold">${{ formatNumber(getTotalProducts()) }}</div>
          </div>
          <div class="flex items-center">
            <div class="font-bold">Envio</div>
            <div class="flex flex-1 justify-end font-bold">${{ tieneEnvioGratis ? 0 : costoEnvio }}</div>
          </div>
          <div class="text-xs">Envio gratis a partir de ${{ minEnvioGratis }}</div>
          <div class="flex items-center">
            <div class="font-bold">Total</div>
            <div class="flex flex-1 justify-end font-bold">${{ formatNumber(getTotal()) }}</div>
          </div>
        </div>

        <!-- Formas de pago -->
        <div class="my-2">
          <Select v-if="getFromUser('nombre')" class="w-60" :placeholder="'Seleccione forma de pago'" :canSearch="false"
            :options="formasDePagoOptions" v-model:selected="formasDePagoSelected"></Select>
        </div>

        <!-- Formas de pago -> Tarjeta -->
        <div class="w-3/5">
          <div v-if="formasDePagoSelected == 1" class="flex flex-wrap justify-start sm:justify-end gap-2">
            <Select class="w-40" :placeholder="'Plan de pagos'" :canSearch="false" :options="planDePagoOptions"
              v-model:selected="pagoTarjeta.plan"></Select>
            <Select :placeholder="'Tipo de tarjeta'" :canSearch="false" :options="tipoTarjetaOptions"
              v-model:selected="pagoTarjeta.tipo"></Select>
            <input placeholder="Número de tarjeta" type="text" v-model="pagoTarjeta.numero"
              class="pl-1 border rounded" />
            <input placeholder="Nombre que figura en la tarjeta" type="text" v-model="pagoTarjeta.nombre"
              class="w-60 pl-1 border rounded" />
            <input placeholder="Código de seguridad" type="text" v-model="pagoTarjeta.codigo" class="pl-1 border rounded" />
            <div class="flex flex-wrap gap-2 items-center">
              <span>Vencimiento:</span>
              <input placeholder="Mes" type="text" v-model="pagoTarjeta.mes" class="w-20 pl-1 border rounded" />
              <input placeholder="Año" type="text" v-model="pagoTarjeta.anio" class="w-20 pl-1 border rounded" />
              <img src="@/assets/logo-decidir.png" class="w-10 h-10 object-cover" alt="Logo decidir" />
              <img src="@/assets/logo-verifiedvisa.png" class="w-10 h-10 object-contain" alt="Logo Visa" />
            </div>
          </div>
        </div>

        <div class="mt-2 col-span-2">
          <div v-if="!getFromUser('telefono')"
            class="bg-main hover:bg-main-darker transition duration-400 cursor-pointer text-white rounded py-2 px-4 text-center"
            @click="goToDatosCompra">Continuar compra</div>
          <button v-if="getFromUser('telefono')" :disabled="loadingComprar || !formasDePagoSelected"
            :class="[{ 'cursor-pointer hover:bg-main-darker bg-main': !loadingComprar && formasDePagoSelected },
            { 'cursor-default bg-gray-600': loadingComprar || !formasDePagoSelected }, 'transition duration-400 text-white rounded py-2 px-4 text-center flex items-center']" @click="goToComprar">
            <div v-if="loadingComprar">
              <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4" />
                <path class="opacity-75" fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
              </svg>
            </div>
            <span>Comprar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router/index";
import axios from "axios";
import { useStore } from "vuex";
import { ref } from "@vue/reactivity";
import InputCantidad from "../components/elements/InputCantidad.vue";
import Select from "../components/elements/Select.vue";
import { apiComprar, apiGetProvincias, apiGetFacturas, apiGetTiposPago, apiGetCostoEnvio } from "@/utils/endpoints"
import { showToast, formatNumber } from "@/utils/functions"
import { computed, reactive } from "vue";

export default {
  components: { InputCantidad, Select },
  name: "Checkout",
  setup() {
    const store = useStore();

    // Para poder usar v-model sin que toque VueX
    let carrito = ref(JSON.parse(JSON.stringify(store.state.carrito)));
    let user = ref(JSON.parse(JSON.stringify(store.state.user)));

    const getTotalParcial = (price, qty) => {
      return price * qty;
    };

    const getTotalProducts = () => {
      return carrito.value.reduce((acum, product) => {
        return acum + getTotalParcial(product.precio, product.cantidad);
      }, 0);
    };

    const getTotal = () => {
      return getTotalProducts() + (tieneEnvioGratis.value ? 0 : parseInt(costoEnvio.value));
    };

    const remove = (id) => {
      store.commit("removeProduct", id);
      let index = carrito.value.findIndex((product) => product.id == id);
      carrito.value.splice(index, 1);
    };

    let costoEnvio = ref("");
    let minEnvioGratis = ref(0);
    axios.get(apiGetCostoEnvio()).then(data => {
      costoEnvio.value = parseInt(data.data['Costo envío'].content);
      minEnvioGratis.value = parseInt(data.data['Monto entrega gratis'].content);
    })

    const goToRepuesto = (id) => {
      router.push({ path: `/repuesto/${id}` });
    };
    const goToDatosCompra = () => {
      router.push({ name: "DatosCompra" })
    }
    let loadingComprar = ref(false);
    const goToComprar = () => {
      loadingComprar.value = true;
      axios.post(apiComprar(), {
        order: {
          order_comments: getFromUser('comentarios'),
          invoice_type_id: getFromUser('factura'),
          payment_type_id: formasDePagoSelected.value,
          "payment_plan": pagoTarjeta.plan,
          "card_number": pagoTarjeta.numero,
          "card_expiration_month": pagoTarjeta.mes,
          "card_expiration_year": pagoTarjeta.anio,
          "card_security_code": pagoTarjeta.codigo,
          "card_holder_name": pagoTarjeta.tipo
        },
        cart: carrito.value.map(producto => {
          return {
            "product_id": producto.id,
            "qty": producto.cantidad,
          }
        }),
        user: {
          "firstname": getFromUser('nombre'),
          "lastname": getFromUser('apellido'),
          "province_code": getFromUser('provincia'),
          "city": getFromUser('ciudad'),
          "zip": getFromUser('cp'),
          "address": getFromUser('direccion'),
          "email": getFromUser('email'),
          "telephone": getFromUser('telefono'),
          "dni": getFromUser('dni'),
          "password": getFromUser('password'),
          "user_id": getFromUser("user_id")
        }
      }).catch(err => {
        console.log(err);
        showToast("Ha occurido un error, reintente", "danger")
        loadingComprar.value = false;
      }).then(response => {
        let data = response.data;
        router.push({
          name: "CompraRealizada", params: {
            id: data[0].order_id,
            tipo_compra: formasDePagoSelected.value
          }
        })
        loadingComprar.value = false;
      })
    }

    const logueado = true;

    const formasDePagoOptions = [];
    axios.get(apiGetTiposPago()).then(data => {
      data = data.data.map(d => {
        return {
          value: d.order_payment_type_id,
          text: d.name
        }
      })
      formasDePagoOptions.push(...data)
    })
    let formasDePagoSelected = ref("");



    let pagoTarjeta = reactive({
      plan: 'Visa',
      tipo: '1',
      numero: "",
      nombre: "",
      dni: "",
      mes: "",
      anio: "",
      codigo: "",
    })

    const tipoTarjetaOptions = [
      { value: 'Visa', text: 'Visa' },
      { value: 'Mastercard', text: 'Mastercard' },
    ]
    const planDePagoOptions = [
      { value: 1, text: '1 pago' },
      { value: 3, text: '3 cuotas' },
      { value: 6, text: '6 cuotas' },
      { value: 12, text: 'Ahora 12' },
      { value: 18, text: 'Ahora 18' },
    ]

    const getFromUser = (field) => {
      if (user.value.length == 0) {
        return false
      }
      return user.value.find(c => c.id == field)?.model;
    }

    axios.get(apiGetProvincias()).then(data => {
      let provinciaStr = data.data.find(provincias => {
        return provincias.province_code == getFromUser('provincia')
      }).province
      user.value.push({ id: 'provinciaStr', model: provinciaStr });
    })
    axios.get(apiGetFacturas()).then(data => {
      let facturaStr = data.data.find(facturas => {
        return facturas.invoice_type_id == getFromUser('factura')
      }).invoice_type;
      user.value.push({ id: 'facturaStr', model: facturaStr });
    })

    const tieneEnvioGratis = computed(() => {
      return getTotalProducts() >= minEnvioGratis.value;
    });

    return {
      carrito,
      getTotalParcial,
      getTotalProducts,
      getTotal,
      remove,
      goToRepuesto,
      store,
      goToDatosCompra,
      logueado,
      formasDePagoOptions,
      formasDePagoSelected,
      planDePagoOptions,
      pagoTarjeta,
      tipoTarjetaOptions,
      user,
      getFromUser,
      goToComprar,
      loadingComprar,
      costoEnvio,
      minEnvioGratis,
      tieneEnvioGratis,
      formatNumber
    };
  },
};
</script>

<style scoped>
table {
  width: 100%;
}

td {
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>

